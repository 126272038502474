/**
 * Created by Andrey Popov on 07.04.2025
 */

var ScrollTableView = cleverapps.UI.ScrollView.extend({
    ctor: function (table, RowViewCtor) {
        var styles = cleverapps.styles.ScrollTableView;
        
        var container = this.container = new cc.Node();
        container.setAnchorPoint(0.5, 0.5);

        this._super(container, {
            childrenVisibility: cleverapps.UI.ScrollView.CHILDREN_VISIBILITY_NONE,
            direction: cleverapps.UI.ScrollView.DIR_VERTICAL,
            scrollBarEnabled: false,
            mouseScroll: false,
            clipping: false
        });
        this.touchScrollDisabled = true;
        
        this.table = table;
        this.RowViewCtor = RowViewCtor;

        this.setContentSize2(styles.size);        

        this.rowsViews = this.table.getRows().map(function (row) {
            return new RowViewCtor(row);
        });

        for (var i = 0; i < this.rowsViews.length; i++) {
            this.container.addChild(this.rowsViews[i]);
            this.rowsViews[i].setPosition(this.calculateRowPosition(this.rowsViews[i]));
        }

        this.updateSize();

        this.table.on("add", this.add.bind(this), this);
        this.table.on("remove", this.remove.bind(this), this);
        this.table.on("move", this.move.bind(this), this);
        this.table.on("swap", this.swap.bind(this), this);
    },

    add: function (row, insertIndex) {
        var rowView = new this.RowViewCtor(row);
        this.rowsViews.splice(insertIndex, 0, rowView);

        this.container.addChild(rowView);
        rowView.setPosition(this.calculateRowPosition(rowView));

        rowView.runAction(rowView.addRowAnimation());

        this.updateContainerSize();
    },

    remove: function (index) {
        var rowView = this.rowsViews[index];
        this.rowsViews.splice(this.rowsViews.indexOf(rowView), 1);

        rowView.runAction(rowView.rowRemoveAnimation());

        this.updateContainerSize();
    },

    move: function (index, newIndex) {
        var styles = cleverapps.styles.ScrollTableView;
        var margin = styles.margin[cleverapps.resolution.mode];

        var rowView = this.rowsViews[index];

        var delta = cc.p(0, 0);
        if (newIndex < index) {
            if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
                delta = cc.p(-rowView.width - margin, 0);
            } else {
                delta = cc.p(0, rowView.height + margin);
            }
        }

        rowView.runAction(new cc.Sequence(
            new cc.DelayTime(2.4),
            new cc.MoveTo(0.5, cc.pAdd(rowView.getPosition(), delta)).easing(cc.easeBackInOut())
        ));
    },

    swap: function (index, newIndex) {
        var rowView1 = this.rowsViews[index];
        rowView1.stopAllActions();
        rowView1.runAction(new cc.MoveTo(0.5, this.calculateRowPosition(rowView1, newIndex)).easing(cc.easeBackInOut()));

        var rowView2 = this.rowsViews[newIndex];
        rowView2.stopAllActions();
        rowView2.runAction(new cc.MoveTo(0.5, this.calculateRowPosition(rowView2, index)).easing(cc.easeBackInOut()));

        this.rowsViews[index] = rowView2;
        this.rowsViews[newIndex] = rowView1;
    },

    updateContainerSize: function () {
        var styles = cleverapps.styles.ScrollTableView;

        var margin = styles.margin[cleverapps.resolution.mode];
        var padding = styles.padding[cleverapps.resolution.mode];

        var rowView = this.rowsViews[0];
        if (!rowView) {
            return;
        }

        var width;
        var height;
        var countOfRows = Math.max(this.table.minIconsCount, this.rowsViews.length);
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            height = rowView.height;
            width = rowView.width * countOfRows + (countOfRows - 1) * margin;
        } else {
            width = rowView.width;
            height = rowView.height * countOfRows + (countOfRows - 1) * margin;
        }

        this.container.setContentSize2(width + 2 * padding.x, height + 2 * padding.y);
    },

    updateSize: function () {
        this.rowsViews.forEach(function (rowView) {
            rowView.stopAllActions();
            rowView.setPosition(this.calculateRowPosition(rowView));
        }.bind(this));

        this.updateContainerSize();

        var styles = cleverapps.styles.ScrollTableView;
        this.setContentSize2(styles.size);

        this.setContentSize(this.width, this.height);
        this.updateInnerContent();
    },

    calculateRowPosition: function (rowView, index) {
        var styles = cleverapps.styles.ScrollTableView;
        var margin = styles.margin[cleverapps.resolution.mode];
        var padding = styles.padding[cleverapps.resolution.mode];
        
        var isVert = cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL;

        index = index || this.rowsViews.indexOf(rowView);
        if (!isVert) {
            index = Math.max(this.table.minIconsCount, this.rowsViews.length) - 1 - index;
        }

        if (isVert) {
            return cc.p(padding.x + (margin + rowView.width) * index + rowView.width / 2, padding.y + rowView.height / 2);
        }

        return cc.p(padding.x + rowView.width / 2, padding.y + (margin + rowView.height) * index + rowView.height / 2);
    }
});

cleverapps.styles.ScrollTableView = {
    size: [
        {
            width: 700,
            height: 160
        },
        {
            width: 500,
            height: 899
        },
        {
            width: 500,
            height: 899
        }
    ],

    margin: [
        25,
        60,
        60
    ],

    padding: [
        {
            x: 10,
            y: 0
        },
        {
            x: 0,
            y: 30
        },
        {
            x: 0,
            y: 30
        }
    ]
};
