/**
 * Created by slava on 23.03.17.
 */

var FingerView = cc.Node.extend({
    ctor: function (options) {
        options = options || {};

        this._super();

        this.setAnchorPoint(0.5, 0.5);

        this.finger = new cleverapps.Spine(options.image && options.image instanceof VirtualJson ? options.image : bundles.finger.jsons.tutorial_hand_spine);
        this.finger.setPosition(0, 0);
        this.addChild(this.finger);

        if (cleverapps.gameModes.showFingerAnchor) {
            this.showAnchorPoint();
        }

        this.setCascadeOpacityEnabledRecursively(true);
        this.scheduleUpdateWithPriority(2);

        if (bundles.finger.jsons.tutorial_path_spine) {
            this.swipePath = new cleverapps.Spine(bundles.finger.jsons.tutorial_path_spine);
            this.swipePath.setVisible(false);
            this.finger.addChild(this.swipePath, -1);
        }
    },

    clear: function () {
        delete this.actions;
        delete this.options;
    },

    updateSize: function () {
        this.reset();
    },

    reset: function () {
        this.stopPlaying();
        this.resumePlaying();
    },

    stopPlaying: function () {
        this.stopped = true;
        this.finger.stopAllActions();
        this.finger.setVisible(false);
        this.pressed = false;

        if (this.pathNode) {
            this.pathNode.reset();
        }

        if (this.payload) {
            this.payload.stopAllActions();
            this.payload.setVisible(false);
        }

        if (this.swipePath) {
            this.swipePath.stopAllActions();
            this.swipePath.setVisible(false);
        }

        if (this.path) {
            for (var i = 0; i < this.path.length; ++i) {
                var step = this.path[i];

                if (step.unselect) {
                    step.unselect();
                }
            }
        }
    },

    resumePlaying: function () {
        this.stopped = false;
        this.play(this.options);
    },

    update: function () {
        var target = this.target;
        if (!target) {
            return;
        }

        if (!target.node.isRunning()) {
            FingerView.remove(this);
            return;
        }

        if (!this.skipLoop) {
            var visible = cleverapps.focusManager.checkEventNode(target.node) && target.node.isDisplayed();
            this.setVisible(visible);
        }

        var position = this.parent.convertToNodeSpace(target.node.convertToWorldSpace(target.position));
        this.setPosition(position.x + this.targetOffset.x + (this.rotationOffsetX || 0), position.y + this.targetOffset.y + (this.rotationOffsetY || 0));
    },

    enablePath: function (color) {
        this.pathNode = new cleverapps.Path(cc.color(color.r, color.g, color.b, FingerView.PATH_BASE_ALPHA));
        this.pathNode.lineWidth = FingerView.PATH_LINE_WIDTH;
        this.pathNode.setLocalZOrder(-2);
        this.addChild(this.pathNode);
        this.pathNode.runAction(new cc.RepeatForever(new cc.Sequence(
            new cc.DelayTime(0.02),
            new cc.CallFunc(function () {
                this.pathNode.draw(this.finger.getPosition());
            }.bind(this))
        )));
    },

    initPosition: function (target, needRotate) {
        this.setTarget(target);

        if (needRotate) {
            for (var node = target.node; node; node = node.parent) {
                if (typeof Map2dInnerView !== "undefined" && node instanceof Map2dInnerView) {
                    needRotate = false;
                }
            }
        }
        if (needRotate) {
            var clippingNode = cleverapps.scenes.getRunningScene();
            var clippingNodePos = clippingNode.convertToNodeSpace(target.node.convertToWorldSpace(target.position));

            clippingNodePos.y -= this.finger.height * this.finger.anchorY;
            clippingNodePos.x += this.finger.width * (1 - this.finger.anchorX);

            var rotation = 0;
            if (clippingNodePos.y <= 0 && clippingNodePos.x >= clippingNode.width) {
                rotation = 205;
            } else if (clippingNodePos.y <= 0) {
                rotation = 115;
            }

            if (rotation === 0) {
                this.rotationOffsetX = this.rotationOffsetY = 0;
            } else {
                var r = rotation * (Math.PI / 180);
                this.rotationOffsetX = this.scaleX * (-this.getAnchorX() - this.getAnchorX() * Math.cos(r) + this.getAnchorY() * Math.sin(r));
                this.rotationOffsetY = this.scaleY * (this.getAnchorY() + this.getAnchorX() * Math.sin(r) + this.getAnchorY() * Math.cos(r));
            }

            this.setRotation(rotation);
        }

        var position = this.convertToNodeSpace(target.node.convertToWorldSpace(target.position));
        this.payload && this.payload.setPosition(position);
        this.finger.setPosition(position);

        this.update();
    },

    moveTo: function (source, target, options) {
        options = options || {};

        var duration = options.duration !== undefined ? options.duration : this.getMoveDuration(source, target);

        var createMoveAction = function (duration, position) {
            var moveAction = new cc.MoveTo(duration, position);

            if (options.easing) {
                moveAction = moveAction.easing(cc.easeCubicActionOut());
            }

            return moveAction;
        };

        return new cc.Sequence(
            new cc.CallFunc(function () {
                this.setTarget(target);

                var position = this.convertToNodeSpace(target.node.convertToWorldSpace(target.position));

                if (this.payload) {
                    this.payload.runAction(createMoveAction(duration, position));
                }

                if (options.showSwipePath) {
                    this.showSwipePath(position, duration);
                }
                this.finger.setAnimation(0, "swipeIdle", true);
                this.finger.runAction(createMoveAction(duration, position));
            }.bind(this)),
            new cc.DelayTime(duration),
            new cc.CallFunc(function () {
                if (this.pathNode && this.pressed) {
                    this.pathNode.push(this.finger.getPosition());
                }
            }.bind(this))
        );
    },

    swipePress: function () {
        var onPress = function () {
            this.pressed = true;

            if (this.pathNode) {
                this.pathNode.reset();
                this.pathNode.push(this.finger.getPosition());
            }
        }.bind(this);

        var animation = "swipePress";
        return new cc.Sequence(
            new cc.SpineAction(this.finger, animation),
            new cc.DelayTime(this.finger.getAnimationData(animation).duration),
            new cc.CallFunc(onPress)
        );
    },

    swipeRelease: function () {
        var onRelease = function () {
            this.pressed = false;

            if (this.pathNode) {
                this.pathNode.animateFadeOut(0.4);
            }
        }.bind(this);

        var animation = "swipeRelease";
        return new cc.Sequence(
            new cc.SpineAction(this.finger, animation),
            new cc.DelayTime(this.finger.getAnimationData(animation).duration),
            new cc.CallFunc(onRelease)
        );
    },

    showSwipePath: function (position, duration) {
        this.swipePath.runAction(new cc.Sequence(
            new cc.CallFunc(function () {
                this.swipePath.setPositionRound(this.getAnchorX(), this.getAnchorY());
                this.swipePath.setRotation((Math.atan2(position.y - this.finger.y, this.finger.x - position.x) * (180 / Math.PI) + 360) % 360);
            }.bind(this)),
            new cc.DelayTime(0.05),
            new cc.SpineAction(this.swipePath, "showup"),
            new cc.Show(),
            new cc.DelayTime(this.swipePath.getAnimationData("showup").duration),
            new cc.SpineAction(this.swipePath, "idle"),
            new cc.DelayTime(Math.max(0, duration - this.swipePath.getAnimationData("showup").duration)),
            new cc.SpineAction(this.swipePath, "hide"),
            new cc.DelayTime(this.swipePath.getAnimationData("hide").duration),
            new cc.Hide()
        ));
    },

    prepareTarget: function (target) {
        if (target.node && target.position) {
            return {
                node: target.node,
                position: target.position
            };
        }

        if (target instanceof FingerView) {
            return {
                node: this,
                position: this.finger.getPosition()
            };
        }

        if (target instanceof cc.Node) {
            return {
                node: target,
                position: cc.p(target.width / 2, target.height / 2)
            };
        }

        if (typeof BaseBlock !== "undefined" && target instanceof BaseBlock) {
            return {
                node: cleverapps.scenes.getRunningScene().keypadView,
                position: BaseBlockView.alignInTheGrid(target.x, target.y),
                select: function () {
                    target.highlight();
                },
                unselect: function () {
                    target.unhighlight();
                }
            };
        }

        if (typeof KeypadLetter !== "undefined" && target instanceof KeypadLetter) {
            var view = target.onLocateView();
            return {
                node: view,
                position: cc.p(view.width / 2, view.height / 2)
            };
        }

        if (typeof DifferenceArea !== "undefined" && target instanceof DifferenceArea) {
            return {
                node: cleverapps.scenes.getRunningScene().photos.imageB,
                position: PhotoViews.FrameSizeAndPos(target)
            };
        }

        var mapView = typeof Map2d !== "undefined" && Map2d.currentMap && Map2d.currentMap.getMapView();
        if (mapView) {
            return {
                node: mapView.animations,
                position: mapView.getUnitCenterPos(target.x, target.y)
            };
        }

        var battlefieldView = Game.currentGame && Game.currentGame.battlefield && Game.currentGame.battlefield.onGetView();
        if (battlefieldView) {
            return {
                node: battlefieldView,
                position: target
            };
        }

        var fieldView = Game.currentGame && Game.currentGame.field && Game.currentGame.field.onGetView();
        if (fieldView) {
            return {
                node: fieldView,
                position: Array.isArray(target) ? BaseCellView.alignInTheGrid(target[0], target[1]) : BaseCellView.alignInTheGrid(target.col, target.row)
            };
        }

        var gridView = Game.currentGame && Game.currentGame.grid && Game.currentGame.grid.getView();
        if (gridView) {
            return {
                node: gridView,
                position: GridView.cellToPosition(target)
            };
        }

        if (cleverapps.config.debugMode) {
            throw "unknown finger target";
        }

        return target;
    },

    setTarget: function (target) {
        this.target = target;
        this.targetOffset = cc.pSub(this.getPosition(), this.parent.convertToNodeSpace(target.node.convertToWorldSpace(target.position)));
    },

    showPayload: function (options) {
        return new cc.Sequence(
            new cc.CallFunc(function () {
                if (this.payload) {
                    this.payload.removeFromParent();
                    delete this.payload;
                }
                this.payload = options.getPayload();
                this.payload.replaceParentSamePlace(this, {
                    keepScale: true
                });
                this.payload.setLocalZOrder(-1);
                this.payload.setVisible(true);
                this.payload.runAction(new cc.ScaleTo(0.2, this.payload.getScale() * (options.payloadScale || 1.3)));
            }.bind(this)),
            new cc.DelayTime(0.2)
        );
    },

    play: function (options) {
        options = options || {};

        this.actions = options.actions || this.actions;
        if (!this.actions) {
            return;
        }

        var actions = this.actions.map(function (action) {
            if (typeof action === "function") {
                return action();
            }
            return action;
        });
        if (options.callback || options.runOnce) {
            actions.push(new cc.CallFunc(function () {
                if (!options.keepShown) {
                    FingerView.remove(this);
                }
                if (options.callback) {
                    options.callback();
                }
            }.bind(this)));
        }

        actions = new cc.Sequence(actions);

        this.finger.stopAllActions();
        this.finger.runAction(
            new cc.Sequence(
                new cc.DelayTime(options.delay || 0),
                actions,
                new cc.CallFunc(function () {
                    if (options.callback || options.runOnce || this.stopped) {
                        return;
                    }
                    var loopOptions = Object.assign({}, options);
                    loopOptions.delay = options.repeatDelay !== undefined ? options.repeatDelay : 0.8;
                    this.play(loopOptions);
                }.bind(this))
            )
        );
    },

    hintTap: function (targets, options) {
        this.options = options = options || {};
        targets = cleverapps.toArray(targets).map(function (target) {
            return this.prepareTarget(target);
        }.bind(this));

        this.actions = [];
        this.initPosition(targets[0], true);

        if (options.loopFilter) {
            this.actions.push(new cc.CallFunc(function () {
                this.skipLoop = !options.loopFilter();
                if (this.skipLoop) {
                    this.setVisible(false);
                }
            }.bind(this)));
        }

        targets.forEach(function (target, index) {
            this.actions.push(new cc.DelayTime(0.2));
            this.actions.push(new cc.CallFunc(function () {
                this.initPosition(target, true);
            }.bind(this)));
            this.actions.push(new cc.Sequence(
                new cc.SpineAction(this.finger, "tap"),
                new cc.DelayTime(this.finger.getAnimationData("tap").duration)
            ));

            if (index !== targets.length - 1) {
                this.actions.push(new cc.DelayTime(1.4));
            }
        }.bind(this));

        this.play(options);
    },

    hintDrag: function (path, options) {
        this.options = options = options || {};

        path = this.path = path.map(function (target) {
            return this.prepareTarget(target);
        }, this);

        this.actions = [];
        var step = path[0];
        this.initPosition(step);

        this.actions.push(new cc.CallFunc(function () {
            this.initPosition(path[0]);
        }.bind(this)));
        this.actions.push(this.swipePress());

        if (step.select) {
            this.actions.push(new cc.CallFunc(step.select));
        }

        if (options.getPayload) {
            this.actions.push(this.showPayload(options));
        }

        for (var i = 1; i < path.length; ++i) {
            step = path[i];

            this.actions.push(this.moveTo.bind(this, path[i - 1], step, {
                easing: options.moveWithEasing
            }));

            if (step.select) {
                this.actions.push(new cc.CallFunc(step.select));
            }
        }

        if (options.getPayload) {
            this.actions.push(new cc.CallFunc(function () {
                if (this.payload) {
                    this.payload.removeFromParent();
                    delete this.payload;
                }
            }.bind(this)));
        }

        for (i = 0; i < path.length; ++i) {
            step = path[i];

            if (step.unselect) {
                this.actions.push(new cc.CallFunc(step.unselect));
            }
        }

        this.actions.push(this.swipeRelease());
        this.play(options);
    },

    hintSwipe: function (from, to, options) {
        from = this.prepareTarget(from);
        to = this.prepareTarget(to);

        this.initPosition(from);

        this.actions = [
            new cc.CallFunc(function () {
                this.initPosition(from);
            }.bind(this)),
            this.swipePress(),
            this.moveTo.bind(this, from, to, {
                showSwipePath: true
            }),
            this.swipeRelease()
        ];

        this.play(options);
    },

    getMoveDuration: function (origin, target, speed) {
        origin = this.convertToNodeSpace(origin.node.convertToWorldSpace(origin.position));
        target = this.convertToNodeSpace(target.node.convertToWorldSpace(target.position));

        var distance = cc.pDistance(origin, target);
        var duration = distance / (speed || cleverapps.styles.FingerView.speed);
        // sigmoid function: limit max duration to 1 but approx. liner under 0.5
        duration = Math.pow(2, 3 * duration);
        duration = (duration - 1) / (duration + 1);
        return duration;
    },

    getAnchorX: function () {
        return this.finger.width * this.finger.anchorX * this.finger.scaleX;
    },

    getAnchorY: function () {
        return this.finger.height * this.finger.anchorY * this.finger.scaleY;
    },

    showAnchorPoint: function () {
        var point = new cc.Sprite(bundles.pixel.frames.pixel_png);
        point.setColor(cc.color(255, 0, 0, 0));
        point.setScale(15);
        this.finger.addChild(point);

        point.setPositionRound(this.getAnchorX(), this.getAnchorY());

        this.finger.debugBorder();
    },

    getOverlapNode: function () {
        return this.finger;
    }
});

FingerView.isRunning = function (finger) {
    return finger && FingerView.currentFinger === finger;
};

FingerView.remove = function (finger, silent) {
    if (!finger || !FingerView.isRunning(finger)) {
        return;
    }

    FingerView.currentFinger = undefined;

    if (finger.isRunning()) {
        finger.clear();
        finger.stopAllActions();
        finger.finger.stopAllActions();
        if (silent) {
            finger.removeFromParent();
            return;
        }

        var actions = [];

        actions.push(new cc.RemoveSelf());

        finger.runAction(new cc.Sequence(
            actions
        ));
    }
};

FingerView.create = function (options) {
    options = options || {};

    var finger = new FingerView(options);

    FingerView.remove(FingerView.currentFinger);
    FingerView.currentFinger = finger;

    cleverapps.scenes.getRunningScene().addChild(finger);

    var baseScale = connector.info.isMobile ? cleverapps.styles.FingerView.mobileScale[cleverapps.resolution.mode] : 1;
    if (typeof options.scale === "object") {
        finger.setScaleX(baseScale * (options.scale.x || 1));
        finger.setScaleY(baseScale * (options.scale.y || 1));
    } else {
        finger.setScale(baseScale * (options.scale || 1));
    }

    finger.setLocalZOrder(BaseWindow.WINDOWS_ZORDER + 10000);
    finger._setGlobalZOrder(true);

    addCleaner(finger, function () {
        FingerView.remove(finger);
    });

    return finger;
};

FingerView.hintTap = function (target, options) {
    options = options || {};

    var finger = FingerView.create(options);
    finger.hintTap(target, options);

    return finger;
};

FingerView.hintDrag = function (steps, options) {
    options = options || {};

    var finger = FingerView.create();

    if (options.pathColor) {
        finger.enablePath(options.pathColor);
    }

    finger.hintDrag(steps, options);

    return finger;
};

FingerView.hintSwipe = function (from, to, options) {
    var finger = FingerView.create();
    finger.hintSwipe(from, to, options || {});
    return finger;
};

FingerView.PATH_BASE_ALPHA = 255;
FingerView.PATH_LINE_WIDTH = 15;
FingerView.FINGER_CLICKED_ANGLE = 7;

cleverapps.styles.FingerView = {
    speed: 350,

    mobileScale: [1, 1.4, 1.4]
};
